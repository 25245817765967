import { GoogleAnalyticsPage } from '@/apollo/fragments/GoogleAnalytics';

const query = gql`
  query getFrontPage {
    isFrontPage {
      title
      renderContent
      seo {
        pageDescription
        pageTitle
      }
      ...GTM
    }
    generalSettings {
      description
    }
  }
  ${GoogleAnalyticsPage}
`;

export default query;
